// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-page-info-js": () => import("./../../../src/templates/page/info.js" /* webpackChunkName: "component---src-templates-page-info-js" */),
  "component---src-templates-projects-index-js": () => import("./../../../src/templates/projects/index.js" /* webpackChunkName: "component---src-templates-projects-index-js" */),
  "component---src-templates-projects-post-js": () => import("./../../../src/templates/projects/post.js" /* webpackChunkName: "component---src-templates-projects-post-js" */)
}

